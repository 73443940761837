import { createRouter, createWebHistory } from 'vue-router'
import moment from 'moment'
import { jwtDecode } from 'jwt-decode'

const routes = [
  {
    path: '/single-wallet-testing',
    name: 'SingleWalletTesting',
    meta: {
      title: 'Single-wallet Testing 单一钱包测试 | AWC API',
    },
    component() {
      return import(/* webpackChunkName: 'single-wallet-testing' */ '../views/SingleWalletTesting.vue')
    },
  },
  {
    path: '/tssw',
    name: 'TSSW',
    redirect: '/tssw/login',
    meta: {
      title: 'Single-wallet Agent 单一钱包代理 | TSSW',
      favicon: '/tssw_favicon.svg',
    },
    component() {
      return import(/* webpackChunkName: 'tssw' */ '../views/TSSW.vue')
    },
    children: [
      {
        path: 'login',
        name: 'TSSWLogin',
        meta: {
          title: 'Login | TSSW',
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'login' */ '../components/TSSW/Login.vue')
          },
        },
      },
      {
        path: 'create-member',
        name: 'CreateMember',
        meta: {
          title: 'Create Member | TSSW',
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'create-member' */ '../components/TSSW/CreateMember.vue')
          },
        },
      },
      {
        path: 'enter-game',
        name: 'TSSWEnterGame',
        meta: {
          title: 'Enter Game | TSSW',
          needLogin: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'enter-game' */ '../components/TSSW/EnterGame.vue')
          },
        },
      },
      {
        path: 'user-settings',
        name: 'UserSettings',
        meta: {
          title: 'User Settings | TSSW',
          needLogin: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'user-settings' */ '../components/TSSW/UserSettings.vue')
          },
        },
      },
      {
        path: 'update-bet-limit',
        name: 'UpdateBetLimit',
        meta: {
          title: 'Update Bet Limit | TSSW',
          needLogin: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'update-bet-limit' */ '../components/TSSW/UpdateBetLimit.vue')
          },
        },
      },
      {
        path: 'member-list',
        name: 'MemberList',
        meta: {
          title: 'Member List | TSSW',
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'member-list' */ '../components/TSSW/MemberList.vue')
          },
        },
      },
    ],
  },
  {
    path: '/testerportal',
    name: 'TesterPortal',
    meta: {
      title: 'Tester Portal',
      favicon: '/testerportal_favicon.svg',
    },
    component() {
      return import(/* webpackChunkName: 'tester-portal' */ '../views/TesterPortal.vue')
    },
    children: [
      {
        path: '',
        name: 'TesterPortalHome',
        meta: {
          title: 'Home | Tester Portal',
          requiresAuth: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'login' */ '../components/TesterPortal/Home.vue')
          },
        },
      },
      {
        path: 'login',
        name: 'TesterPortalLogin',
        meta: {
          title: 'Login | Tester Portal',
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'login' */ '../components/TesterPortal/Login.vue')
          },
        },
      },
      {
        path: 'player-list',
        name: 'PlayerList',
        meta: {
          title: 'Player List | Tester Portal',
          requiresAuth: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'player-list' */ '../components/TesterPortal/PlayerList.vue')
          },
        },
      },
      {
        path: 'ratio-table',
        name: 'RatioTable',
        meta: {
          title: 'Ratio Table | Tester Portal',
          requiresAuth: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'user-list' */ '../components/TesterPortal/RatioTable.vue')
          },
        },
      },
      {
        path: 'user-agent-list',
        name: 'UserAgentList',
        meta: {
          title: 'User Agent List | Tester Portal',
          requiresAuth: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'user-agent-list' */ '../components/TesterPortal/UserAgentList.vue')
          },
        },
      },
      {
        path: 'user-list',
        name: 'UserList',
        meta: {
          title: 'User List | Tester Portal',
          requiresAuth: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'user-list' */ '../components/TesterPortal/UserList.vue')
          },
        },
      },
      {
        path: 'user-level-permissions',
        name: 'UserLevelPermissions',
        meta: {
          title: 'User Level Permissions | Tester Portal',
          requiresAuth: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'user-list' */ '../components/TesterPortal/UserLevelPermissions.vue')
          },
        },
      },
      {
        path: 'enter-game',
        name: 'TesterPortalEnterGame',
        meta: {
          title: 'Enter Game | Tester Portal',
          requiresAuth: true,
        },
        components: {
          default() {
            return import(/* webpackChunkName: 'user-list' */ '../components/TesterPortal/EnterGame.vue')
          },
        },
      },
    ],
  },
  {
    path: '/api-doc/:wallet/:edition',
    name: 'ApiDoc',
    component() {
      return import(/* webpackChunkName: 'api-doc' */ '../views/ApiDoc.vue')
    },
  },
  {
    path: '/api-doc/openapi-editor',
    name: 'OpenApiEditor',
    meta: { title: 'OpenAPI Editor | AWC API' },
    component() {
      return import(/* webpackChunkName: 'openapi-editor' */ '../views/OpenApiEditor.vue')
    },
  },
  {
    path: '/redoc-previewer',
    name: 'RedocPreviewer',
    meta: { title: 'Redoc Previewer | AWC API' },
    component() {
      return import(/* webpackChunkName: 'redoc-previewer' */ '../views/RedocPreviewer.vue')
    },
  },
  {
    path: '/public/:pageId',
    name: 'PublicPage',
    meta: { title: 'Public Page | AWC' },
    component() {
      return import(/* webpackChunkName: 'public-page' */ '../views/PublicPage.vue')
    },
  },
  {
    path: '/maint-board/:chatId',
    name: 'MaintBoard',
    meta: { title: 'System Maintenance Schedule 系统维护时间表 | AWC' },
    component() {
      return import(/* webpackChunkName: 'maint-board' */ '../views/MaintBoard.vue')
    },
  },
  {
    path: '/evolution-assets',
    name: 'EvolutionAssets',
    meta: { title: 'Evolution Assets' },
    component() {
      return import(/* webpackChunkName: 'public-page' */ '../views/EvolutionAssets.vue')
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  // console.log({ to, from })
  let pageTitle
  if (to.path.match(/^\/api-doc\/(single|multi)-wallet\/(PRD|IGK|WinBox)/)) {
    const { wallet, edition } = to.params

    let walletCnText
    if (wallet === 'single-wallet') walletCnText = '单一钱包'
    if (wallet === 'multi-wallet') walletCnText = '转帐钱包'

    const walletStrArr = wallet.split('-').map(word => word[0].toUpperCase() + word.substr(1))
    if (edition === 'PRD') {
      pageTitle = `${walletStrArr.join(' ')} ${walletCnText} | AWC API Doc`
    } else {
      pageTitle = `${walletStrArr.join(' ')} ${walletCnText} (${edition}) | AWC API Doc`
    }
  } else {
    pageTitle = to.meta.title
  }

  if (to.meta.favicon) {
    const link = document.querySelector("[rel='icon']")
    link.setAttribute('href', to.meta.favicon)
  }

  document.title = pageTitle

  if (to.path.includes('testerportal')) {
    const { requiresAuth } = to.meta
    const token = localStorage.getItem('token')
    const lastActivity = localStorage.getItem('lastActivity')
    // console.log({ token, lastActivity })

    if (requiresAuth) {
      if (token && lastActivity) {
        try {
          const decoded = jwtDecode(token)
          const now = moment().unix()
          const isTokenExpired = decoded.exp <= now
          const isIdle = now - lastActivity > 30 * 60

          if (isTokenExpired || isIdle) {
            // console.log(111, 'token expired || idle => /login', { isTokenExpired, isIdle })
            if (isTokenExpired) localStorage.removeItem('token')
            return next('/testerportal/login')
          }
        } catch (error) { // token decoded error
          console.error(error)
          localStorage.removeItem('token')
          return next('/testerportal/login')
        }
      } else {
        // console.log(333, 'no token or lastActivity => /login')
        return next('/testerportal/login')
      }
    } else {
      if (token && lastActivity) {
        const decoded = jwtDecode(token)
        const now = moment().unix()
        const isTokenExpired = decoded.exp <= now
        const isIdle = now - lastActivity > 30 * 60

        if (!isTokenExpired && !isIdle) {
          // console.log(444, '已登入+進到 /testerportal/login')
          return next('/testerportal')
        }
      }
      return next()
    }
  }

  // console.log(555, 'next')
  return next()
})

export default router
